import React  from "react";
import tableHeader from "../../data/AppListVersion/TableHeader.js";
import TotalTable from "../../data/AppListVersion/TotalTable.js";
import TotalSumTable from "../BaseComponent/TotalSumTable";
import PercentTable from "../BaseComponent/PercentTable";
import Select from "react-select";
import Moment from "moment/moment";

const TableAppListVersion = (parameters) => {
    const [loading, setLoading] = parameters.loading
    const [selectedOptionVersion, setSelectedOptionVersion] = parameters.selectVersion;
    const [appOptionVersion, setAppOptionVersion] = parameters.appOptionVersion;

    function tableBody(item) {
        return (
            <>
                <td>
                    <div>
                        {item.trials ?? '-'}
                        <PercentTable data={item.trials_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.paid_trials ?? '-'}
                        <PercentTable data={item.paid_trials_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.trial_to_sub ? item.trial_to_sub + '%' : '-'}
                        <PercentTable data={item.trial_to_sub_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.direct_sub ?? '-'}
                        <PercentTable data={item.direct_sub_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.arpu ? item.arpu + '$' : '-'}
                        <PercentTable data={item.arpu_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.arpue ? item.arpue + '$' : '-'}
                        <PercentTable data={item.arpue_percent}/>
                    </div>
                </td>
                <td>
                    <div>
                        {item.arpu_ad_1_day ? item.arpu_ad_1_day + '$' : '-'}
                        <PercentTable data={item.arpu_ad_1_day_percent}/>
                    </div>
                </td>
            </>
        );
    }

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px', width: '100px'}),
        menuList: base => ({...base, maxHeight: "100px", width: '100px'}),
        indicatorsContainer: () => ({display: "block"}),
        menu: (base) => ({
            ...base,
            width: "100px"
        }),
    };

    function optionsSelectVersions(versions) {
        const options = [];
        versions.map((item => {
            options.push(
                {
                    label: item,
                    value: item,
                }
            )
        }));

        return options;
    }

    const handleSelectChange = (option, packageName, selectedVersion) => {
        setSelectedOptionVersion(prevState => (
            {
                ...prevState,
                [packageName]: {
                    ...prevState[packageName],
                    [selectedVersion]: option
                }
            }
        ));

        setAppOptionVersion(prevState => (
            {
                ...prevState,
                [packageName]: {
                    ...prevState[packageName],
                    [selectedVersion]: option.value
                }
            }
        ))
        setLoading(true);
    };

    function currentValueSelected(selected, item, key) {
        if(selected) {
            return item?.[selected.value];
        } else {
            return item?.[key];
        }
    }

    function installDateVersionRender(selectedOptionVersion, itemVersions, key) {
        const dateVersionDefault = Moment(itemVersions[Object.keys(itemVersions)[key]]).format('DD.MM.YYYY').toString();
        const dateVersionSelect = selectedOptionVersion
            ? Moment(itemVersions[selectedOptionVersion.value]).format('DD.MM.YYYY').toString()
            : null;

        return (
            <div className="version_install_date">
                <span>Last install date:</span>
                <span>
                    {selectedOptionVersion ? dateVersionSelect : dateVersionDefault}
                </span>
            </div>
        );
    }

    return (
        loading ? <div className="loader_page">
                <div className="lds-dual-ring"></div>
            </div> :
            <>
                <TotalSumTable
                    data={parameters.dataDataListVersion}
                    nameVariable='Sum'
                    tableHeader={tableHeader}
                    tableBody={TotalTable}
                />
                <div className="table_app_list_version">
                    <table>
                        <tbody>
                        <tr>
                            <th className="aplication">Application</th>
                            <th>Trials</th>
                            <th>Paid trials</th>
                            <th>Trial to Sub</th>
                            <th>Direct sub</th>
                            <th>ARPU</th>
                            <th>ARPUe</th>
                            <th>ARPU Ad 1 day</th>
                        </tr>
                        {parameters.dataDataListVersion.App_list.map((itemParent, key) => {
                    if (itemParent.available) {
                        return (
                            <React.Fragment key={key}>
                                <tr>
                                    <td className="app_list_table__aplication">
                                        <div className="aplication_content">
                                            <img
                                                className="app_list_table__image"
                                                src={itemParent.icon}
                                                alt=""
                                            />
                                            <div className="content">
                                                <div className="content__app_name">{itemParent.package}</div>
                                            </div>
                                        </div>
                                        <div className="computer_comment">
                                            <span className="computer">
                                                {itemParent.computer ? '(' + itemParent.computer + ') ' : null}
                                            </span>
                                            <span className="comment">
                                                {itemParent.comment}
                                            </span>
                                        </div>
                                    </td>
                                    {parameters.allPackageVersionTable[itemParent.package] ? tableBody(parameters.allPackageVersionTable[itemParent.package]?.overall_data) : ''}
                                </tr>
                                <tr>
                                    <td className="app_list_table__aplication_version">
                                        <span>Version</span>
                                        <Select
                                            value={selectedOptionVersion[itemParent.package]?.first}
                                            options={optionsSelectVersions(Object.keys(itemParent.versions), itemParent.package)}
                                            onChange={(option) => handleSelectChange(option, itemParent.package, 'first')}
                                            defaultValue={{label: Object.keys(itemParent.versions)[0], value: Object.keys(itemParent.versions)[0]}}
                                            placeholder="Search"
                                            styles={styleSelect}
                                        />
                                        {installDateVersionRender(selectedOptionVersion[itemParent.package]?.first, itemParent.versions, 0)}
                                    </td>
                                    {
                                        parameters.allPackageVersionTable[itemParent.package] ? tableBody(
                                            currentValueSelected(selectedOptionVersion[itemParent.package]?.first,
                                            parameters.allPackageVersionTable[itemParent.package],
                                            Object.keys(itemParent.versions)[0])
                                        ) : null
                                    }
                                </tr>
                                {Object.keys(itemParent.versions).length > 1 ?
                                    <tr>
                                        <td className="app_list_table__aplication_version">
                                            <span>Version</span>
                                            <Select
                                                value={selectedOptionVersion[itemParent.package]?.second}
                                                defaultValue={{
                                                    label: Object.keys(itemParent.versions)[1],
                                                    value: Object.keys(itemParent.versions)[1]
                                                }}
                                                options={optionsSelectVersions(Object.keys(itemParent.versions), itemParent.package)}
                                                onChange={(option) => handleSelectChange(option, itemParent.package, 'second')}
                                                placeholder="Search"
                                                styles={styleSelect}
                                            />
                                            {installDateVersionRender(selectedOptionVersion[itemParent.package]?.second, itemParent.versions, 1)}
                                        </td>
                                        {
                                            parameters.allPackageVersionTable[itemParent.package] ? tableBody(
                                                currentValueSelected(selectedOptionVersion[itemParent.package]?.second,
                                                    parameters.allPackageVersionTable[itemParent.package],
                                                    Object.keys(itemParent.versions)[1])
                                            ) : null
                                        }
                                    </tr>
                                    :
                                    null
                                }
                            </React.Fragment>
                        );
                    }
                })}
                </tbody>
            </table>
        </div>
    </>
    );
}

export default TableAppListVersion;