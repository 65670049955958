import React, {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";

const TopBarMarketing = (parameters) => {
    const userName = localStorage.getItem('first_name');
    const lastName = localStorage.getItem('last_name');

    const handleLogout = () => {
        localStorage.removeItem('authToken');
    };

    const [valueSelect, setValueSelect] = parameters.valuePackageSelect;
    const [loading, setLoading] = parameters.loading;
    const [appListData, setAppListData] = useState();

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseListApp = await axios.get(
                    `https://gpanalytic.net/analytic/apps`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setAppListData(responseListApp?.data?.App_list);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, []);

    const styleSelect = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #DFE0EB',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:focus': {
                border: '0 !important',
            },
            fontSize: '20px',
            width: '630px',
            height:  'auto'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#E7EBFF' : null,
            color: 'black',
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '630px',
            zIndex: '100',
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    const handleSelectChange = (selectedOption) => {
        setValueSelect(selectedOption);
        setLoading(true);
    };


    return (
        <div className="top_bar">
            <div className="top_bar__search_form">
                <Select
                    options={appListData}
                    value={valueSelect}
                    onChange={handleSelectChange}
                    styles={styleSelect}
                    getOptionLabel={(option) => option.package}
                    getOptionValue={(option) => option.id}
                    formatOptionLabel={option => (
                        <div className="app-select-option">
                            {option.icon ?  <img src={option.icon} height="30px" width="30px" alt=""/> : null}
                            <span>
                    {option.package}
                                <span className="option_computer"> {option.computer ? '(' + option.computer + ')' : null} </span>
                    <span className="option_comment"> {option.comment}</span>
                </span>
                        </div>
                    )}
                    placeholder={parameters.placeholder ?? 'Search'}
                />
            </div>
            <div className="top_bar__panel">
                <div className="top_bar__user">{userName} {lastName}</div>
                <div className="top_bar__logout">
                    <button onClick={handleLogout}>Logout</button>
                </div>
            </div>
        </div>
    );
};

export default TopBarMarketing;