import React  from "react";
import Select from "react-select";

const CountrySelect = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [selectStates, setSelectStates] = parameters.selectStates;
    const [selectedOption, setSelectedOption] = parameters.selectedOption;

    const countryAvailable = [];

    parameters.countryData.map(item => {
        if(item.available) {
            countryAvailable.push({
                label: `(${item.code}) ${item.country_name}`,
                value: item.code
            });
        }
    });

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "200px"}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
         if(selectedOption.value === 'ALL') {
             setSelectStates({
                 [selectedOption.value]: true
             });
         } else {
             if(selectStates.ALL) {
                 setSelectStates([]);
             }
             setSelectStates((prevState) => (
                 {
                     ...prevState,
                     [selectedOption.value]: true,
                 }
             ));
         }
       setLoading(true);
    };

    const handleCheckboxClick = (value) => {
        setSelectStates((prevStates) => {
            const newStates = {
                ...prevStates,
                [value]: !prevStates[value],
            };
            setSelectedOption(countryAvailable.find((element) => element.value === value));
            setLoading(true);
            return newStates;
        });
    };

    function multiOptions() {
        return countryAvailable.map((item) => {
            if(selectStates[item.value] && selectStates[item.value] !== undefined) {
                return (
                    <div key={item.value} className="button-checked" onClick={() => handleCheckboxClick(item.value)}>{item.value}</div>
                );
            }
        })
    }

    return (
       <div className="selected_wrapper">
           <Select
               value={selectedOption}
               onChange={handleSelectChange}
               options={countryAvailable}
               isSearchable
               className="filters__search_input"
               placeholder="Search"
               styles={styleSelect}
           />
           <div className="selected_multi_options">
               {multiOptions()}
           </div>
       </div>
    );
}

export default CountrySelect;