import React from "react";
import Select from "react-select";

const VersionPackageSelect = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [valueSelect, setValueSelect] = parameters.valueVersionPackage;
    const [selectStatesVersionPackage, setSelectStatesVersionPackage] = parameters.selectStatesVersionPackage;

    const option = parameters?.valuePackageSelect && parameters.valuePackageSelect?.[0]?.versions
        ? Object.entries(parameters.valuePackageSelect?.[0]?.versions).map(([key, value]) => {
           return (
               {
                   label: key,
                   value: key,
               }
           )
            })
        : [];

    const styleSelect = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #DFE0EB',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:focus': {
                border: '0 !important',
            },
            fontSize: '20px',
            width: '500px',
            height: '55px',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#E7EBFF' : null,
            color: 'black',
        }),
        menu: (provided, state) => ({
            ...provided,
            width: '500px',
            zIndex: '100',
        }),
        indicatorSeparator: () => ({display: "none"}),
    };

    const handleSelectChange = (selectedOption) => {
        setValueSelect(selectedOption);
        setSelectStatesVersionPackage((prevState) => (
            {
                ...prevState,
                [selectedOption.value]: true,
            }
        ));
        setLoading(true);
    };

    const handleCheckboxClick = (value) => {
        setSelectStatesVersionPackage((prevStates) => {
            const newStates = {
                ...prevStates,
                [value]: !prevStates[value],
            };
            setSelectStatesVersionPackage(option.find((element) => element.value === value));
            setLoading(true);
            return newStates;
        });
    };

    function multiOptions() {
        return option.map((item) => {
            if(selectStatesVersionPackage[item.value] && selectStatesVersionPackage[item.value] !== undefined) {
                return (
                    <div key={item.value} className="button-checked" onClick={() => handleCheckboxClick(item.value)}>{item.value}</div>
                );
            }
        })
    }

    return (
        <div className="version_package_wrapper">
            <Select
                value={valueSelect}
                onChange={handleSelectChange}
                options={option}
                isSearchable
                className="filters__search_input"
                placeholder="Search"
                styles={styleSelect}
            />
            <div className="selected_multi_options">
                {multiOptions()}
            </div>
        </div>
    );
}

export default VersionPackageSelect;