import React from "react";
import CountrySelect from "./Filters/CountrySelect";
import DateRange from "./Filters/DateRange";
import Checkbox from "./Filters/Checkbox";
import PackageSelect from "./Filters/PackageSelect";
import DateRangeMonth from "./Filters/DateRangeMonth";
import VersionPackageSelect from "./Filters/VersionPackageSelect";

const AppListDetailsFilters = (parameters) => {
    return (
        <div className="filters">
            <div className="filters__wrapper">
                {parameters.dateRangeInput === 'day'
                    ? <DateRange
                        dateRange={parameters.dateRange}
                        loading={parameters.loading}/>
                    : <DateRangeMonth
                        dateRange={parameters.dateRange}
                        loading={parameters.loading}/>
                }
                <CountrySelect
                    countryData={parameters.countryData}
                    loading={parameters.loading}
                    selectedOption={parameters.selectedOption}
                    selectStates={parameters.selectStates}/>
                <Checkbox
                    checkboxState={parameters.checkboxState}
                    loading={parameters.loading}/>
            </div>
            <div className="filters__wrapper">
                <PackageSelect
                    loading={parameters.loading}
                    valuePackageSelect={parameters.valuePackageSelect}
                    style={{width: '410px', height: '54px'}}
                    placeholder={'Search by bundle id'}
                    selectStatesVersionPackage={parameters.selectStatesVersionPackage}
                    valueVersionPackage={parameters.valueVersionPackage}
                />
                <VersionPackageSelect
                     loading={parameters.loading}
                     valuePackageSelect={parameters.valuePackageSelect}
                     valueVersionPackage={parameters.valueVersionPackage}
                     selectStatesVersionPackage={parameters.selectStatesVersionPackage}
                />
            </div>
        </div>
    );
}

export default AppListDetailsFilters;