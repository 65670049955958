import React, {useState, useEffect, useRef} from "react";
import DatePicker from "react-datepicker";

const DateRange = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [dateRange, setDateRange] = parameters.dateRange;
    const [startDate, endDate] = dateRange;

    function dateLimit(){
        const dateLimitEnd = structuredClone(dateRange[0]);
        const maxDateRange = dateLimitEnd.setMonth(dateLimitEnd.getMonth() + 11);

        if(dateRange[1] === null && maxDateRange < new Date()) {
            return maxDateRange;
        }
        return new Date();
    }

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    return (
        <>
            <DatePicker
                disabledKeyboardNavigation
                maxDate={dateLimit()}
                dateFormat="dd.MM.yyyy"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateChange}
                isClearable={true}
                placeholderText="Date range"
            />
        </>
    );
}

export default DateRange;