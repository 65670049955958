import React  from "react";
import Select from "react-select";

const CountrySelect = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [selectStates, setSelectStates] = parameters.selectStates;
    const [selectedOption, setSelectedOption] = parameters.selectedOption;

    const countryAvailable = [];

    parameters.countryData.map(item => {
        if(item.available) {
            countryAvailable.push({
                label: `(${item.code}) ${item.country_name}`,
                value: item.code
            });
        }
    });

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "270px"}),
        indicatorSeparator: () => ({ display: "none" }),
        indicatorsContainer: () => ({ display: "none" }),
        singleValue: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px" }),
        ValueContainer: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px" }),
        input: () => ({ margin: "4px 10px", display: "inline-grid", gridArea:" 1/1/2/3", gridTemplateColumns: "10px"}),
        placeholder: () => ({marginLeft: '22px',  gridArea:" 1/1/2/3", })
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectStates((prevState) => (
            {
                ...prevState,
                [selectedOption.value]: true,
            }
        ));
       setLoading(true);
    };

    return (
       <div className="selected_wrapper">
           <Select
               value={selectedOption}
               onChange={handleSelectChange}
               options={countryAvailable}
               isSearchable
               className="filters__search_input geo"
               styles={styleSelect}
           />
       </div>
    );
}

export default CountrySelect;