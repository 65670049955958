import React from "react";
import PercentTable from "./PercentTable";

const TotalSumTable = (parameters) => {
    return (
        <>
            <div className="total">
                <table>
                    <tbody>
                    <tr>
                        <th className="total__first_col">{parameters.nameVariable}</th>
                        {parameters.tableHeader.map((item, index) => {
                            return (<th key={index} className={item.className}>{item.name}</th>);
                        })}
                    </tr>
                    <tr>
                        <td className="total__first_col"></td>
                        {parameters.tableBody.map((item, index) => {
                            return (
                                <td key={index}>
                                    {parameters.data[parameters.nameVariable][item] ?? '-'}
                                    <PercentTable data={parameters.data[parameters.nameVariable][item + '_percent']}/>
                                </td>);
                        })}
                    </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TotalSumTable;