import React, {useEffect, useState} from 'react';
import "../styles/appList.scss";
import axios from "axios";
import Select from "react-select";

function CurrentABTests() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [countryData, setCountryData] = useState();
    const countryAvailable = [];
    const [selectedOption, setSelectedOption] =  useState([]);

    const [dataGeo, setDataGeo] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseCountry = await axios.get(
                    `https://gpanalytic.net/analytic/getAllCountries`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );
                setCountryData(responseCountry?.data.Countries);

                const response = await axios.get(
                    `https://gpanalytic.net/analytic/currentAbTests`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setData(response?.data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, [selectedOption]);

    const styleSelect = {
        control: (provided) => ({...provided, fontSize: '12px'}),
        menuList: base => ({...base, maxHeight: "200px", zIndex: '100px', position: ''}),
        indicatorsContainer: () => ({ display: "none" }),
        singleValue: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px",  zIndex: '100px' }),
        ValueContainer: () => ({ left: "20px", position: "relative", top: "-15px", height: "10px",  zIndex: '100px' }),
        input: () => ({ margin: "4px 10px", display: "inline-grid", gridArea:" 1/1/2/3", gridTemplateColumns: "10px"}),
        placeholder: () => ({marginLeft: '22px',  gridArea:" 1/1/2/3", })
    };
    if(countryData) {
        countryData.map(item => {
            if(item.available) {
                countryAvailable.push({
                    label: `(${item.code}) ${item.country_name}`,
                    value: item.code
                });
            }
        })
    }

    const handleSelectChange = (option, packageName) => {
        setSelectedOption(prevState => (
            {
                ...prevState,
                [packageName]: {
                    ...prevState[packageName],
                    label: option.label,
                    value: option.value,
                }
            }
        ))
        setLoading(false);
        axios.post(
           `https://gpanalytic.net/analytic/currentPackageSubAbTests`,
           {
               "countries": [option.value],
               "package": packageName
           },
           {
               headers: {
                   Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
               }
           }
        ).then(response => {
             setDataGeo(prevState => (
                 {
                     ...prevState,
                     [packageName]: {
                         ...prevState[packageName],
                         versions: response.data?.versions
                     }
                 }
             ))
             setLoading(true);
        })
        .catch(error => {
            console.error('There was an error!', error);
        });
    };

    function renderVersions(value, key) {
        return (
            <div className="ab_test_item__package_version" key={key}>
                <div className="ab_test_item__version">
                    <span>{value.version_comment}</span>
                    <span>{key}</span>
                </div>
                <div className="trial_subscriptions__wrapper">
                    {Object.entries(value.subcriptions).map( ([keySecond, valueSecond]) => {
                        return (
                            <div className="trial_subscriptions__item" key={keySecond+valueSecond.trial}>
                                <div className="trial_subscriptions__item__name">{keySecond}:</div>
                                <div className="trial_subscriptions__item__trial">{valueSecond.trial ?? '-'}</div>
                                <div className="trial_subscriptions__item__subscriptions">{valueSecond.subscriptions ?? '-'}</div>
                                <div className="trial_subscriptions__item__price">{valueSecond.price ? valueSecond.price+'$' : '-'}</div>
                                <div className="trial_subscriptions__item__revenue">{valueSecond.revenue ? valueSecond.revenue+'$' : '-'}</div>
                            </div>
                        );
                    })}

                </div>
            </div>
        );
    }

    function renderItem(item, key, name, selectShow) {
        return (
            <div className="ab_test_item" key={key + name}>
                <div className="ab_test_item__package">
                    <img src={item.package_icon} alt=""/>
                    <div className="ab_test_item__package_computer">{item.computer}</div>
                    <div className="ab_test_item__package_name">{item.package}</div>
                    <div className="ab_test_item__package_comment">{item.comment}</div>
                </div>
                <div className="ab_test_item__additions">
                    <div>
                        {dataGeo[item.package]
                            ? Object.entries(dataGeo[item.package].versions).map(
                                ([key, value]) => {
                                    return (renderVersions(value, key))
                                })
                            : item.versions ? Object.entries(item.versions).map(
                                ([key, value]) => {
                                    return (renderVersions(value, key))
                                }) : null
                        }
                    </div>
                    <div className="description_wrapper">
                        {selectShow ?
                            <Select
                                value={selectedOption[item.package]}
                                defaultValue={countryAvailable[0]}
                                options={countryAvailable}
                                onChange={(option) => handleSelectChange(option, item.package)}
                                placeholder="Search"
                                className="filters__search_input geo"
                                styles={styleSelect}
                            />
                            : null
                        }
                        <div className="description">
                            {item?.description}
                        </div>
                        <div className="link">
                            {item.link ? <a href={item.link} target="_blank">Figma</a> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        loading ? <div className="loader_page">
                <div className="lds-dual-ring"></div></div> :
            <div className="page ab_test">
                <div className="ab_test_item__title">AB Sub</div>
                {data.ab_sub.map((item, key) => {
                    return (renderItem(item, key, 'Subscriptions', true))
                })}
                <div className="ab_test_item__title">AB Icon</div>
                {data.ab_icon.map((item, key) => {
                    return (renderItem(item, key, 'Screenshots',  false))
                })}
            </div>
    );
}

export default CurrentABTests;