import React, {useEffect, useState} from 'react';
import DatePicker from "react-datepicker";
import TopBarMarketing from "../components/TopBarmarketing";
import axios from "axios";
import icon_app_details_table from "../img/icon_app_details_table.svg";
import Moment from "moment";

function MarketingViewCampaign() {
    const [loading, setLoading] = useState(true);
    const [valuePackageSelect, setValuePackageSelect] = useState({
        available: true,
        icon: "https://newapi.godoko-fsp.com/media/icons_tmp/Icon_1.png",
        id: 854,
        package: "com.watch.wearables.notifier"
    });
    const [dateRange, setDateRange] = useState([
        new Date(Date.now() - 8 * 24 * 60 * 60 * 1000),
        new Date(Date.now() - 24 * 60 * 60 * 1000),
    ]);
    const [startDate, endDate] = dateRange;
    const [data, setData] = useState(null);

    const [hiddenElements, setHiddenElements] = useState(['0campaigns']);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const response = await axios.post(
                    `https://gpanalytic.net/analytic/marketingCampaign`,
                    {
                        "start_date": Moment(dateRange[0]).format('YYYY-MM-DD').toString(),
                        "end_date": Moment(dateRange[1]).format('YYYY-MM-DD').toString(),
                        "package": valuePackageSelect?.package ? valuePackageSelect?.package : "com.bluetooth.mobile.connect.goodpositivemole"
                    },
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                if(Object.keys(response.data).length > 0) {
                    setData(response.data)
                } else {
                    setData(null);
                }

                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, [dateRange, valuePackageSelect]);

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    const handleClickVisibleElements = (event) => {
        const clickedElementId = event.target.id;

        if (hiddenElements.includes(clickedElementId)) {
            setHiddenElements(hiddenElements.filter(id => id !== clickedElementId));
        } else {
            setHiddenElements([...hiddenElements, clickedElementId]);
        }
    };

    function renderTableItems(item, key, nameItem, keyParent) {
        return (
            <table key={key+nameItem}>
                <tbody>
                <tr>
                    <td
                        onClick={handleClickVisibleElements}
                        id={keyParent+key+nameItem}
                    >
                        <div
                            className={"marketing_campaign__item_name "+nameItem}
                            onClick={handleClickVisibleElements}
                            id={keyParent+key+nameItem}
                        >
                            {nameItem !== 'creatives' ? <img
                                src={icon_app_details_table}
                                onClick={handleClickVisibleElements}
                                id={keyParent+key+nameItem}
                                alt=""/> : null}
                            <span
                                onClick={handleClickVisibleElements}
                                id={keyParent+key+nameItem}
                            >{nameItem} {item?.campaign_name ?? item?.ad_group_name ?? item?.creative_name}</span>
                        </div>
                    </td>
                    <td>{item.installs ?? '-'}</td>
                    <td>
                        <span>{item.trials ?? '-'}</span>
                        <span className="paid_trials">{item.paid_trials}</span>
                    </td>
                    <td>{item.subs ?? '-'}</td>
                    <td>{item.ad_revenue ? item.ad_revenue+'$' : '-'}</td>
                    <td>{item.total_revenue ? item.total_revenue+'$' : '-'}</td>
                    <td>{item.total_cost ? item.total_cost+'$' : '-'}</td>
                    <td>{item.estimated_revenue ? item.estimated_revenue+'$' : '-'}</td>
                </tr>
                </tbody>
            </table>
        );
    }

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
               <>
        <TopBarMarketing
            loading={[loading, setLoading]}
            valuePackageSelect={[valuePackageSelect, setValuePackageSelect]}
        />
       { data ?
        <div className="page page_geo_settings marketing_campaign">
            <div className="filters">
                <DatePicker
                    disabledKeyboardNavigation
                        dateFormat="dd.MM.yyyy"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={handleDateChange}
                        isClearable={true}
                        placeholderText="Date range"
                    />
            </div>
                <table>
                    <tbody>
                    <tr>
                        <th></th>
                        <th>Installs</th>
                        <th>Trials</th>
                        <th>Subs</th>
                        <th>Ad Revenue</th>
                        <th>Total Revenue</th>
                        <th>Total Cost</th>
                        <th>Estimated revenueue</th>
                </tr>
                    <tr className="marketing_campaign__parent">
                        <td>Facebook</td>
                        <td>{data.facebook.installs ?? '-'}</td>
                        <td>{data.facebook.trials ?? '-'}</td>
                        <td>{data.facebook.subs ?? '-'}</td>
                        <td>{data.facebook.ad_revenue ? data.facebook.ad_revenue + '$' : '-'}</td>
                        <td>{data.facebook.total_revenue ? data.facebook.total_revenue + '$' : '-'}</td>
                        <td>{data.facebook.total_cost ? data.facebook.total_cost + '$' : '-'}</td>
                        <td>{data.facebook.estimated_revenue ? data.facebook.estimated_revenue + '$' : '-'}</td>
                    </tr>
                    </tbody>
                </table>
            {Object.entries(data.facebook.campaigns).map(([keyCampaign, campaignItem]) => {
                return (
                    <div className={!hiddenElements.includes(keyCampaign + 'campaigns')
                        ?  'campaigns hidden'
                            : 'campaigns visible'} key={keyCampaign+'campaigns'}>
                            {renderTableItems(campaignItem, keyCampaign, 'campaigns', '')}
                            {Object.entries(campaignItem.ad_groups).map(([adGroupsKey, adGroupsValue]) => {
                               return (
                                   <div className={!hiddenElements.includes(keyCampaign+adGroupsKey+'ad_groups')
                                       ? 'ad_groups hidden'
                                       : 'ad_groups visible'}
                                        key={adGroupsKey+'ad_groups'}
                                   >
                                       {renderTableItems(adGroupsValue, adGroupsKey, 'ad_groups', keyCampaign)}
                                       {Object.entries(adGroupsValue?.creatives).map(([creativesKey, creativesValue]) => {
                                           return (
                                               <div className="creatives" key={creativesKey+'creatives'}>
                                                   {
                                                       renderTableItems(creativesValue, creativesKey, 'creatives', '')}
                                               </div>
                                           )
                                       })}
                                   </div>
                               )
                            })}
                        </div>
                    );
                })}
                </div>
                   :
            <div className="page page_geo_settings marketing_campaign">
               <div className="filters">
                   <DatePicker
                       disabledKeyboardNavigation
                       dateFormat="dd.MM.yyyy"
                       selectsRange={true}
                       startDate={startDate}
                       endDate={endDate}
                       onChange={handleDateChange}
                       isClearable={true}
                       placeholderText="Date range"
                   />
               </div>
                   <div className="no_data">
                       No data
                   </div>
               </div>
            }
        </>
   );
}

export default MarketingViewCampaign;