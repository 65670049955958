import React, {useEffect, useState} from 'react';
import Moment from "moment";
import axios from "axios";
import AppListDetailsFilters from "../components/AppListDetails/AppListDetailsFilters";
import TableAppListDetail from "../components/AppListDetails/TableAppListDetail";

function AppDetailsDay() {
    const [loading, setLoading] = useState(true);

    const [countryData, setCountryData] = useState();
    const [selectStates, setSelectStates] = useState([]);
    const [checkboxState, setCheckboxState] = useState({
        Facebook: true,
        Google: true,
        Organic: true,
        Other: true,
    });
    const [dateRange, setDateRange] = useState([
        new Date(Date.now() - 8 * 24 * 60 * 60 * 1000),
        new Date(Date.now() - 24 * 60 * 60 * 1000),
    ]);

    const [tableData, setTableData] = useState();
    const [hiddenElements, setHiddenElements] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [valuePackageSelect, setValuePackageSelect] = useState();
    const [valueVersionPackage, setValueVersionPackage] = useState();
    const [selectStatesVersionPackage, setSelectStatesVersionPackage] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseCountry = await axios.get(
                    `https://gpanalytic.net/analytic/getAllCountries`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );
                setCountryData(responseCountry?.data.Countries);

                const countriesArray = Object.entries(selectStates).map(
                    ([key]) => (key)
                ).filter(key => selectStates[key]);

                const versionArray = Object.entries(selectStatesVersionPackage).map(
                    ([key]) => (key)
                ).filter(key => selectStatesVersionPackage[key]);

                console.log(versionArray)

                const responseApps = await axios.post(
                    `https://gpanalytic.net/analytic/AppDetailsDay`,
                    {
                        "start_date": Moment(dateRange[0]).format('YYYY-MM-DD').toString(),
                        "end_date": Moment(dateRange[1]).format('YYYY-MM-DD').toString(),
                        "countries": countriesArray ? countriesArray: [],
                        "organic": checkboxState.Organic,
                        "facebook": checkboxState.Facebook,
                        "google": checkboxState.Google,
                        "other": checkboxState.Other,
                        "package": valuePackageSelect?.value ? valuePackageSelect?.value  : "",
                        "versions": versionArray ? versionArray : []
                    },
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setTableData(responseApps?.data)
                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchDataFromApi().then(() => {});
    }, [dateRange, selectedOption, checkboxState, valuePackageSelect, selectStatesVersionPackage]);

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page details details">
                <AppListDetailsFilters
                    countryData={countryData}
                    loading={[loading, setLoading]}
                    selectStates={[selectStates, setSelectStates]}
                    dateRange={[dateRange, setDateRange]}
                    checkboxState={[checkboxState, setCheckboxState]}
                    selectedOption={[selectedOption, setSelectedOption]}
                    dateRangeInput={'day'}
                    valuePackageSelect={[valuePackageSelect, setValuePackageSelect]}
                    valueVersionPackage={[valueVersionPackage, setValueVersionPackage]}
                    selectStatesVersionPackage={[selectStatesVersionPackage, setSelectStatesVersionPackage]}
                />
                <div className="first_registration">
                    First registration of choosen version: {tableData.first_registration
                    ? Moment(tableData.first_registration).format('DD.MM.YYYY').toString()
                    : '-'}
                </div>
                <TableAppListDetail
                    tableData={tableData}
                    hiddenElements={[hiddenElements, setHiddenElements]}
                    arpuDay={true}
                />
            </div>
    );
}

export default AppDetailsDay;