import React from "react";

const Checkbox = (parameters) => {

    const checkBoxItems = [
        {   name: 'Organic',
            available: false
        },
        {
            name: 'Facebook',
            available: false
        },
        {
            name: 'Google',
            available: false
        },
        {
            name: 'Other',
            available: false
        }
    ];

    const [checkboxStates, setCheckboxStates] = parameters.checkboxState;

    const handleCheckboxClick = (value, available) => {
        setCheckboxStates((prevStates) => {
            return {
                ...prevStates,
                [value]: (available && prevStates[value] === undefined) ? !available : !prevStates[value],
            };
        });
    };

    return (
        <div className="checkbox-wrapper_app_list">
            {checkBoxItems.map((item, index) => {
                return (
                    <div key={index} className={'checkbox-input_app_list ' + item.name}>
                        <span className="text_label">{item.name}</span>
                        <input
                            readOnly
                            type="checkbox"
                            className='high_load'
                            id={'high_load' + item.name + index}
                            name="high_load"
                            key={item.name}
                            checked={checkboxStates[item.name] ?? item.available}
                            onClick={() => handleCheckboxClick(item.name, item.available)}
                        />
                        <label htmlFor={'high_load' + item.name + index} className="lb"></label>
                    </div>
                );
            })}
        </div>
    );

}

export default Checkbox;