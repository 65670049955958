import React  from "react";
import icon_app_details_table from "../../img/icon_app_details_table.svg"

const TableAppListDetail = (parameters) => {
    const [hiddenElements, setHiddenElements] = parameters.hiddenElements;

    const QuickViewTb = [
        {value: 'cpm', name: 'CPM'},
        {value: 'direct_sub', name: 'Direct subs'},
        {value: 'install_to_sub', name: 'Install to sub %'},
        {value: 'trial_to_sub', name: 'Trials to subs %'},
        {value: 'install_to_trial', name: 'Install to trial'},
        {value: 'impression_per_user', name: 'Impression per user'},
        {value: 'installs', name: 'installs'},
        {value: 'paid_trials', name: 'Paid trials'},
        {value: 'trials', name: 'Trials'},
    ];

    const ARPUParentData = [
        {value: 'ad', name: 'AD'},
        {value: 'sub', name: 'sub'},
        {value: 'sub_expected', name: 'ARPU sub expected'},
        {value: 'combined', name: 'ARPU combine'},
        {value: 'resub_weekly', name: 'resub weekly'},
        {value: 'resub_monthly', name: 'resub monthly'},
        {value: 'resub_weekly_expected', name: 'resub weekly expected'},
        {value: 'resub_monthly_expected', name: 'resub monthly expected'},
    ];

    function renderTableBodyQuickView () {
        return (
            <>
                {QuickViewTb.map((itemParent, keyParent) => {
                   return (
                       <tr key={keyParent}>
                           <td key={keyParent+itemParent.value+'key'} className="item_name">{itemParent.name}</td>
                           {parameters.tableData['quick_view'][itemParent.value].map((item, key) => {
                               return (
                                   <td key={key + item.date}>
                                       {(itemParent.value === 'install_to_sub' || itemParent.value === 'trial_to_sub')
                                           ? item.value ? item.value+' %' : '-'
                                           : item.value ? item.value+' $' : '-'
                                       }
                                   </td>
                               );
                           })}
                       </tr>
                   )
                })}
            </>
        );
    }

    function ARPUNameCorrect(nameItem) {
        if(nameItem.includes("arpu")) {
            return  nameItem.replace("arpu", "ARPU").replaceAll('_', ' ')
        }

        if(nameItem.includes("resubs_monthly"))  {
            return nameItem.replace('resubs_monthly', ' ').replace('expected', ' ').replaceAll('_', ' ');
        }

        if(nameItem.includes("resubs_weekly"))  {
            return nameItem.replace('resubs_weekly', ' ').replace('expected', ' ').replaceAll('_', ' ');
        }

        return nameItem;
    }

    const handleClickVisibleElements = (event) => {
        const clickedElementId = event.target.id;

        if (hiddenElements.includes(clickedElementId)) {
            setHiddenElements(hiddenElements.filter(id => id !== clickedElementId));
        } else {
            setHiddenElements([...hiddenElements, clickedElementId]);
        }
    };

    function renderTableARPUDay() {
        return (
          <React.Fragment>
              {ARPUParentData.map((item, key) => {
                    return (
                        <div key={key}>
                        <div className={!hiddenElements.includes(item.value)
                            ? 'name_ARPU_item visible'
                            : 'name_ARPU_item hidden'}
                             id={item.value}
                             onClick={handleClickVisibleElements}>
                            <img
                                src={icon_app_details_table}
                                 alt=""
                                 id={item.value}
                                 onClick={handleClickVisibleElements}/>
                            <span
                                id={item.value}
                                onClick={handleClickVisibleElements}>
                                {item.name}
                            </span>
                        </div>
                        <div className={!hiddenElements.includes(item.value)
                                 ? 'ARPU_items_wrapper visible'
                                 : 'ARPU_items_wrapper hidden'}>
                            {!parameters.arpuDay ? Object.entries(parameters.tableData['arpu'][item.value]).map(
                                ([key, value]) => {
                                    return (
                                        <div key={key + value}>
                                            <table key={key + value}>
                                                <tbody>
                                                <tr>
                                                    <td className="ARPU_items_wrapper__name">{ARPUNameCorrect(key)}</td>
                                                    {value.map((item, index) => {
                                                        return (
                                                              <td key={index+item.value}>
                                                                  {item.value ?? '-'}
                                                              </td>
                                                        );
                                                    })}
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    );
                                }) :
                            Object.entries(parameters.tableData['arpu'][item.value]).map(
                                ([key, value]) => {
                                    return (
                                        <div className="ARPU_items_wrapper__row" key={key + value}>
                                            <div className="ARPU_items_wrapper__name">
                                                {ARPUNameCorrect(key)}
                                            </div>
                                            <div className="ARPU_items_wrapper__value">{value ?? '-'}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>);
                  })
              }
          </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className={!hiddenElements.includes('Quick_view') ? 'table_title visible' : 'table_title hidden'}
                id="Quick_view"
                onClick={handleClickVisibleElements}>
                <img id="Quick_view" onClick={handleClickVisibleElements} src={icon_app_details_table} alt=""/>
                <span id="Quick_view" onClick={handleClickVisibleElements}>Quick view</span>
            </div>
            <div className="table_container">
                <table className={!hiddenElements.includes('Quick_view') ? 'visible' : 'hidden'}>
                    <tbody>
                    <tr>
                        <th></th>
                        {parameters.tableData['quick_view']['cpm'].map((item, key) => {
                            return (<th key={key + 'th'}>{item.date}</th>);
                        })}
                    </tr>
                    {renderTableBodyQuickView()}
                    </tbody>
                </table>
            </div>
            <div className={!hiddenElements.includes('ARPU') ? 'table_title visible' : 'table_title hidden'}
                 id="ARPU"
                 onClick={handleClickVisibleElements}>
                <img id="ARPU" onClick={handleClickVisibleElements} src={icon_app_details_table} alt=""/>
                <span id="ARPU" onClick={handleClickVisibleElements}>ARPU</span>
            </div>
            <div className={!hiddenElements.includes('ARPU') ? 'ARPU_talbe visible' : 'ARPU_talbe hidden'}>
                { renderTableARPUDay()}
            </div>
        </React.Fragment>
    );
}

export default TableAppListDetail;