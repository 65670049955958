import React from 'react';

const PercentTable  = (parameters) => {
    if(parameters.data) {
        if(String(parameters.data).search('-') === 0) {
            return (
                <span className="percent">
                    <span className="minus">
                        {String(parameters.data).replace('-', '')} %
                    </span>
                </span>
            )
        }
        return (
            <span className="percent">
                <span className="plus">{parameters.data + ' %'}</span>
            </span>
        )
    }
}

export default PercentTable;