import React, {useEffect, useState} from "react";
import Select from "react-select";
import axios from "axios";

const PackageSelect = (parameters) => {
    const [valueSelect, setValueSelect] = parameters.valuePackageSelect;
    const [valueVersionPackage, setValueVersionPackage] = parameters.valueVersionPackage;

    const [loading, setLoading] = parameters.loading;
    const [appListData, setAppListData] = useState();
    const [selectStatesVersionPackage, setSelectStatesVersionPackage] = parameters.selectStatesVersionPackage;

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseListApp = await axios.get(
                    `https://gpanalytic.net/analytic/apps`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setAppListData(responseListApp?.data?.App_list);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, []);


    const styleSelect = {
        control: (provided, state) => ({
            ...provided,
            border: '1px solid #DFE0EB',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:focus': {
                border: '0 !important',
            },
            fontSize: '20px',
            width: parameters.style.width ?? '410px',
            height: parameters.style?.height ?? 'auto'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#E7EBFF' : null,
            color: 'black',
        }),
        menu: (provided, state) => ({
            ...provided,
            width: parameters.style.width ?? '410px',
            zIndex: '100',
        }),
        indicatorSeparator: () => ({ display: "none" }),
    };

    const handleSelectChange = (selectedOption) => {
        setValueSelect(selectedOption);
        setSelectStatesVersionPackage([]);
        setValueVersionPackage([]);
        setLoading(true);
    };

    return (
        <Select
            options={appListData}
            value={valueSelect}
            onChange={handleSelectChange}
            styles={styleSelect}
            getOptionLabel={(option) => option.package}
            getOptionValue={(option) => option.id}
            formatOptionLabel={option => (
            <div className="app-select-option">
                {option.icon ?  <img src={option.icon} height="30px" width="30px" alt=""/> : null}
                <span>
                    {option.package}
                    <span className="option_computer"> {option.computer ? '(' + option.computer + ')' : null} </span>
                    <span className="option_comment"> {option.comment}</span>
                </span>
            </div>
            )}
            placeholder={parameters.placeholder ?? 'Search'}
        />
    );
}

export default PackageSelect;