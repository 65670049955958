import React from "react";
import CountrySelect from "./Filters/CountrySelect";
import DateRange from "./Filters/DateRange";

const FiltersAppListVersion = (parameters) => {

    return (
        <div className="filters">
            <div className="filters__wrapper">
                <DateRange
                    dateRange={parameters.dateRange}
                    loading={parameters.loading}/>
                <CountrySelect
                    countryData={parameters.countryData}
                    loading={parameters.loading}
                    selectedOption={parameters.selectedOption}
                    selectStates={parameters.selectStates}/>
            </div>
        </div>
    );
}

export default FiltersAppListVersion;