import React, {useEffect, useState} from 'react';
import Moment from "moment";
import axios from "axios";
import AppListDetailsFilters from "../components/AppListDetails/AppListDetailsFilters";
import TableAppListDetail from "../components/AppListDetails/TableAppListDetail";

function AppDetailsMonth() {
    const [loading, setLoading] = useState(true);
    const [selectedOptionPackage, setSelectedOptionPackage] = useState([]);
    const [countryData, setCountryData] = useState();
    const [selectStates, setSelectStates] = useState([]);
    const [checkboxState, setCheckboxState] = useState({
        Facebook: true,
        Google: true,
        Organic: true,
        Other: true,
    });

    const currentDate = new Date();
    const defaultDateStart = new Date();
    const defaultDateEnd = new Date();

    defaultDateStart.setMonth(currentDate.getMonth() - 8);
    defaultDateEnd.setMonth(currentDate.getMonth() - 1);

    const [dateRange, setDateRange] = useState([defaultDateStart, defaultDateEnd]);

    const [tableData, setTableData] = useState();
    const [hiddenElements, setHiddenElements] = useState([]);

    const [appListData, setAppListData] = useState();
    const [selectedOption, setSelectedOption] = useState(null);
    const [valuePackageSelect, setValuePackageSelect] = useState(null);
    const [valueVersionPackage, setValueVersionPackage] = useState([]);
    const [selectStatesVersionPackage, setSelectStatesVersionPackage] = useState([]);

    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const responseCountry = await axios.get(
                    `https://gpanalytic.net/analytic/getAllCountries`,
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setCountryData(responseCountry?.data.Countries);

                const countriesArray = Object.entries(selectStates).map(
                    ([key]) => (key)
                ).filter(key => selectStates[key]);

                const versionArray = Object.entries(selectStatesVersionPackage).map(
                    ([key]) => (key)
                ).filter(key => selectStatesVersionPackage[key]);

                const responseApps = await axios.post(
                    `https://gpanalytic.net/analytic/AppDetailsMonth`,
                    {
                        "start_date": Moment(dateRange[0]).format('YYYY-MM').toString(),
                        "end_date": Moment(dateRange[1]).format('YYYY-MM').toString(),
                        "countries": countriesArray ? countriesArray: [],
                        "organic": checkboxState.Organic,
                        "facebook": checkboxState.Facebook,
                        "google": checkboxState.Google,
                        "other": checkboxState.Other,
                        "package": valuePackageSelect?.package ? valuePackageSelect?.package  : "",
                         "versions": versionArray ? versionArray : []
                    },
                    {
                        headers: {
                            Authorization: 'Token 0a7b58445700962080613374fe7144d5290343b6',
                        }
                    }
                );

                setTableData(responseApps?.data)

                setLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDataFromApi().then(() => {});
    }, [dateRange, selectedOption, checkboxState, valuePackageSelect, selectStatesVersionPackage]);

    return (
        loading ? <div className="loader_page"><div className="lds-dual-ring"></div></div> :
            <div className="page details month">
                <AppListDetailsFilters
                    countryData={countryData}
                    loading={[loading, setLoading]}
                    selectedOption={[selectedOption, setSelectedOption]}
                    selectStates={[selectStates, setSelectStates]}
                    dateRange={[dateRange, setDateRange]}
                    checkboxState={[checkboxState, setCheckboxState]}
                    selectedOptionPackage={[selectedOptionPackage, setSelectedOptionPackage]}
                    dateRangeInput={'month'}
                    appListData={appListData}
                    valuePackageSelect={[valuePackageSelect, setValuePackageSelect]}
                    valueVersionPackage={[valueVersionPackage, setValueVersionPackage]}
                    selectStatesVersionPackage={[selectStatesVersionPackage, setSelectStatesVersionPackage]}
                />
                <TableAppListDetail
                    tableData={tableData}
                    hiddenElements={[hiddenElements, setHiddenElements]}
                    arpuDay={false}
                />
            </div>

    );
}

export default AppDetailsMonth;