import React from "react";
import DatePicker from "react-datepicker";

const DateRange = (parameters) => {
    const [loading, setLoading] = parameters.loading;
    const [dateRange, setDateRange] = parameters.dateRange;
    const [startDate, endDate] = dateRange;

    const handleDateChange = (date) => {
        setDateRange(date);
        if(date[0] !== null && date[1] !== null) {
            setLoading(true);
        }
    };

    return (
        <DatePicker
            disabledKeyboardNavigation
            dateFormat="dd.MM.yyyy"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={handleDateChange}
            isClearable={true}
            placeholderText="Date range"
        />
    );
}

export default DateRange;